import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Home from "./Pages/Home";
import Sidebar from "./Components/SiderBar";
import Stock from "./Pages/Stock";
import Items from "./Pages/Items";
import Staff from "./Pages/Staff";
import Newuser from "./Pages/Newuser";
import Edit from "./Pages/users/Edit";
import { ViewStock } from "./Pages/stock/ViewStock";
import History from "./Pages/stock/History";
import Add from "./Pages/stock/Add";
import AddItems from "./Pages/Items/AddItems";
import EditItem from "./Pages/Items/EditItem";
import Layout from "./Components/common/Layout";
import Login from "./Pages/Login";
import "./App.css";
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="stock" element={<Stock />} />
      <Route path="items" element={<Items />} />
      <Route path="users" element={<Staff />} />
      <Route path="newuser" element={<Newuser />} />
      <Route path="/edit/:id" element={<Edit />} />
      <Route path="/stock/View/:id" element={<ViewStock />} />
      <Route path="/stock/history/:id" element={<History />} />
      <Route path="/stock/add" element={<Add />} />
      <Route path="/items/add" element={<AddItems />} />
      <Route path="/items/edit/:id" element={<EditItem />} />
      <Route path="/Auth" element={<Login />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
