"use client";
import React, { useEffect, useState, Fragment } from "react";
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Outlet } from "react-router-dom";
import { Link, NavLink, useNavigate } from "react-router-dom";

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
  { name: "Staff", href: "/users", icon: UsersIcon, current: false },
  { name: "stock", href: "/stock", icon: FolderIcon, current: false },
  { name: "items", href: "/items", icon: CalendarIcon, current: false },
  { name: "Orders", href: "#", icon: DocumentDuplicateIcon, current: false },
  { name: "Kitchen screen", href: "#", icon: ChartPieIcon, current: false },
  { name: "Full Report", href: "#", icon: ChartPieIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SiderBar({ userInfo }) {
  const navigate = useNavigate();
  const [selected, setselected] = useState(0);

  const [userIfo, setUserIfo] = useState({
    role: "",
    name: "",
  });

  const logout = () => {
    localStorage.removeItem("token");
    navigate(0);
    return navigate("/Auth");
  };
  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      return navigate("/Auth");
    }
    let userType = JSON.parse(localStorage.getItem("token")).user;
    setUserIfo({
      role: userType.role,
      name: userType.name,
    });
  }, []);
  return (
    <>
      {userIfo.role == "Admin" && (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=white"
                alt="Your Company"
              />
              <h1 className="text-white text-lg font-semibold ml-2">
                Thatched House
              </h1>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item, index) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-indigo-700 text-white"
                              : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-white"
                                : "text-indigo-200 group-hover:text-white",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                            onClick={() => setselected(index)}
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <span aria-hidden="true" onClick={() => logout()}>
                    Logout
                  </span>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-indigo-700"
                  >
                    <span className="sr-only">Logout</span>
                    <span aria-hidden="true" onClick={() => logout()}>
                      Logout
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
}
