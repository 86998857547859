import React from "react";
import { Outlet } from "react-router-dom";
import SiderBar from "../SiderBar";

export default function Layout() {
  return (
    <React.Fragment>
      <SiderBar />
      <Outlet />
    </React.Fragment>
  );
}
